import { AuthService } from '@okta/okta-react';
import JwtDecode from 'jwt-decode';

const defaultIssuer = 'https://ntrsuat.oktapreview.com/oauth2/ausr4cctuiOSaCNdu0h7';
const defaultClient = '0oaq1scn5jKOpfHzM0h7';
const authService = new AuthService({
  issuer: defaultIssuer || process.env.REACT_APP_OKTA_ISSUER || process.env.CUSTOM_DOMAIN,
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID || defaultClient,
  pkce: true,
});

export const NT_USER = '213';
export const PERMISSION_LEVEL_RESTRICTED = 0;
export const PERMISSION_LEVEL_UNRESTRICTED = 1;

export const getClientIdFromToken = (token: string): string => {
  const { userType } = JwtDecode<{ userType: string | undefined }>(token);

  // UserType could have three types of values, 'NT', 'clientId' or 'clientId-PermissionLevel'.
  if (userType) {
    if (userType === 'NT') {
      return NT_USER;
    }
    // If the permissionLevel suffix is present, remove it to return only the clientId.
    return userType.split('-')[0];
  }
  return '';
};

export const getPermissionFromToken = (token: string): number => {
  const { userType } = JwtDecode<{ userType: string | undefined }>(token);

  // UserType could have three types of values, 'NT', 'clientId' or 'clientId-PermissionLevel'.
  if (userType) {
    if (userType === 'NT') {
      return PERMISSION_LEVEL_UNRESTRICTED;
    }
    // If the permissionLevel suffix is present, the user has restricted permissions.
    // This implementation is currently a hotfix, and should change to be more flexible in the future.
    const userTypeParts = userType.split('-');
    return userTypeParts.length < 2
      ? PERMISSION_LEVEL_UNRESTRICTED
      : PERMISSION_LEVEL_RESTRICTED;
  }
  return PERMISSION_LEVEL_RESTRICTED;
};

export const getActualUserTypeFromToken = (token: string): string => {
  const { userType } = JwtDecode<{ userType: string | undefined }>(token);
  // TODO: fix with real logic for NT user when avil
  if (userType) {
    return userType;
  }
  return '';
};

export default authService;
